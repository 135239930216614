<template>
  <div>
    <ErrorDialog
      v-if="error"
      :error="error"
      :message="errorMessage"
      @exitError="extiError"
    />
    <div class="coin-wrapper">
      <div class="coin-panel d-flex justify-end align-center text-h6">
        {{ blueStar }}
        <v-img
          :src="require('@/assets/item/C000040.png')"
          max-width="30"
          class="ms-2"
        >
        </v-img>
      </div>
    </div>

    <div class="pa-3">
      <div class="d-flex justify-center align-center">
        <div>
          <v-img
        src="@/assets/dailyGame/CB0010_hamochi_color_hamochi_wizard.png"
        class="main-wizard"
      />
        </div>
        <div class="text-h4 ">
          Exchange your blue stars here
          <v-btn
        class="text-h6"
        color="green"
        large
        text
        @click="clickSkip()"
      >
        skip
      </v-btn>
        </div>
      </div>
      
      <v-row class="mx-3" align="center" justify="center">
        <v-col
          v-for="item in items"
          :key="item.key"
          class="px-1 py-3"
          cols="3"
          @click="selectItem(item)"
        >
          <Item
            :imageUrl="item.imageUrl"
            :isTick="false"
            :isSelected="selectedItem.key == item.key"
            :isShowPrice="true"
            :price="item.price"
            :isDisabled="false"
            :quantity="item.quantity"
            :isShowQuantity="true"
          />
        </v-col>
      </v-row>
    </div>
    <div class="text-center">
      <v-btn
        class="text-h6"
        color="yellow"
        large
        @click="clickOk()"
        :disabled="!selectedItem.key"
      >
        {{ $t("action.ok") }}
      </v-btn>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    Item: () =>
      import(
        /* webpackChunkName: "component-dailygame-item" */ "@/components/dailyGame/Item.vue"
      ),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  props: ["blueStar", "callbackClickOk","callbackClickSkip"],
  data: () => ({
    error: false,
    errorMessage: null,
    newBuyer: false,
    bought: false,
    selectedItem: {},
    selectedItemInventory: {},
    buyConfirmDialog: false,
    purchased: false,
    items: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    itemApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    buyApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
   
    //EOC
    data: [],
    coins: [],
    student: [],
    buyItem: false,
    authData: [],
  }),
  created() {
   
    
    this.buyApi.callbackReset = () => {
      this.buyApi.isLoading = true;
      this.buyApi.isError = false;
      this.buyApi.error = null;
    };
    this.avatarSaveApi.callbackReset = () => {
      this.avatarSaveApi.isLoading = true;
      this.avatarSaveApi.isError = false;
      this.avatarSaveApi.error = null;
    };
    this.itemApi.callbackReset = () => {
      this.itemApi.isLoading = true;
      this.itemApi.isError = false;
      this.itemApi.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.buyApi.callbackError = (e) => {
      this.buyApi.isLoading = false;
      this.buyApi.isError = true;
      this.buyApi.error = e;
    };
    this.avatarSaveApi.callbackError = (e) => {
      this.avatarSaveApi.isLoading = false;
      this.avatarSaveApi.isError = true;
      this.avatarSaveApi.error = e;
    };
    this.itemApi.callbackError = (e) => {
      this.itemApi.isLoading = false;
      this.itemApi.isError = true;
      this.itemApi.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      var inventoryItems = resp.Product.filter(function (entry) {
        return entry.Inventory.quantity > 0;
      });
      if (inventoryItems.length == 0) {
        this.newBuyer = true;
      }
      this.authData = this.$_.clone(this.auth);
      this.api.isLoading = false;
      this.items = this.$_.filter(resp.Product, (o) => {
        return o.Item.setKey != null;
      });
      this.itemData = this.$_.groupBy(this.items, (o) => {
        return o.Item.setKey;
      });
      // this.selectItem(this.items[0])
      this.totalPage = Math.ceil(Object.keys(this.itemData).length / 2);
      this.sliceItems();

      // #tutExploreShop - shop tutorial starts
      if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 3
      ) {
        if (this.newBuyer) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreShop";
          tempTut.Step = 4;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreShopChats_1),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreShop";
          tempTut.Step = 7;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreShopChats_2),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        }
      }
    };
    this.buyApi.callbackSuccess = (resp) => {
      this.$store.commit("updateCoin", {
        Currency: { quantity: resp.Currency.quantity },
      });
      this.selectedItemInventory.quantity = resp.Item.quantity;
      this.buyApi.isLoading = false;
      this.$api.fetch(this.api);
      this.saveAvatar();
      this.bought = true;
      this.$intro().exit();
    };
    this.avatarSaveApi.callbackSuccess = (resp) => {
      this.authData.Player = resp.Player;
      this.$store.commit("updateAuth", this.authData);
      this.avatar = this.$_.cloneDeep(JSON.parse(resp.Player.avatar));
      this.avatarSaveApi.isLoading = false;
      this.studentAvatar = this.$_.cloneDeep(resp.Player.avatar);
    };
    this.itemApi.callbackSuccess = (resp) => {
      this.items = resp;
      this.itemApi.isLoading = false;
    };
  },
  mounted() {
    // this.$api.fetch(this.api);
    this.items = [
      {
        key: "goldcoin",
        imageUrl:
          "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/items/icon/goldcoin/1684467370.png",
        price: 5,
        quantity: 10,
      },
      // {
      //   key: "CHEST0010",
      //   imageUrl:
      //     "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/items/icon/CHEST0010/1684467373.png",
      //   price: 10,
      //   quantity: 1,
      // },
      {
        key: "CHEST0020",
        imageUrl:
          "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/items/icon/CHEST0020/1684467374.png",
        price: 15,
        quantity: 1,
      },
      // {
      //   key: "CHEST0030",
      //   imageUrl:
      //     "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/items/icon/CHEST0030/1684467375.png",
      //   price: 20,
      //   quantity: 1,
      // },
    ];
  },
  methods: {
   
    clickOk() {
      if (this.blueStar >= this.selectedItem.price) {
        this.callbackClickOk(this.selectedItem);
      } else {
        this.errorMessage = "Not enough Blue star.";
        this.error = true;
      }
    },
    clickSkip() {
        this.callbackClickSkip();
    },
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    extiError() {
      this.error = false;
    },
    buyConfirm() {
      this.buyApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/buy";
      this.buyApi.params = this.$_.clone({
        quantity: 1,
        itemId: this.selectedItem.id,
      });
      this.$api.fetch(this.buyApi);
    },
    selectItem(item) {
      if (!this.selectedItem != item) {
        this.selectedItem = item;
        this.selectedItemInventory = item;
      }
      // #tutExploreShop - after the shop tutorial intrduction finish it will triger introjs to gide user to click menu button
    },
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.coin-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 480px !important;
  width: 100%;
  z-index: 1;
}
.coin-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  min-width: 120px;
  margin: 10px;
  right: 0px;
  padding: 2px 10px; /* remove this */
  background-color: #fff;
  text-align: right;
}
.burst {
  width: 100vw;
  height: 100vh;
  background-size: cover !important;
  position: absolute;
  opacity: 0.6;
  z-index: 0;
}
.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
.item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  margin-top: 10px;
}

.low-item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px;
  margin-top: 10px;
}
.avatar {
  height: 140px;
  overflow: hidden;
}
.crop {
  width: 70px;
  height: 30px;
  overflow: hidden;
}

.hat {
  width: 100px;
  height: 100px;
  margin: -10px 0 0 -10px;
}
.avatar-hat {
  position: absolute;
}

@media (max-height: 500px) {
  #shop {
    background-size: auto 500px !important;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  padding-bottom: 30px !important;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #ffb8b8; */
  position: relative;
}

.main-body {
  /* background-color: #ffb8b8; */
  background-color: rgba(255, 71, 123, 0.3);
}

.save-confirm {
  position: fixed;
  background-color: #ffb8b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

#shop {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.buy-confirm {
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

#shop-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-plot {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 15px;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}

.main-wizard{
  width: 100px;
}
</style>